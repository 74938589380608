import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
import { Age } from 'src/utils/calculator';

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        alignCenter: { textAlign: 'center' },
        alignItemsCenter: { display: 'flex', alignItems: 'center' },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Roboto',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 40px 24px',
        },
        header: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: 50, // Match this with the paddingTop
          backgroundColor: '#f8f8f8',
          textAlign: 'center',
          justifyContent: 'center',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 12,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableHead: {
          padding: '4px 0 1px 0',
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8',
          backgroundColor: '#cccccc',
        },
        tableRow: {
          padding: '2px 0',
          flexDirection: 'row',
          /* borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: '#DFE3E8', */
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        summary: {
          padding: 10,
          border: '1px solid #000',
          width: '60%',
        },
        tableCell_5: {
          width: '5%',
        },
        tableCell_7: {
          width: '7%',
        },
        tableCell_10: {
          width: '10%',
        },
        tableCell_15: {
          width: '15%',
        },
        tableCell_20: {
          width: '20%',
        },
        tableCell_25: {
          width: '25%',
        },
        tableCell_30: {
          width: '30%',
        },
        tableCell_40: {
          width: '40%',
        },
        tableCell_50: {
          width: '50%',
          paddingRight: 16,
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function OpTransactionReportPDF({ reportData, reportTitle, flgDepartment }) {
  const styles = useStyles();

  function groupByDepartment(data) {
    return data.reduce((groups, item) => {
      const department = item.department_uuid || 'Unknown';
      if (!groups[department]) {
        groups[department] = [];
      }
      groups[department].push(item);
      return groups;
    }, {});
  }

  const groupedReportData = flgDepartment ? groupByDepartment(reportData) : { all: reportData };

  const calcTotals = {
    totalPatients: (filteredData) => filteredData.length,
    newVisitCount: (filteredData) =>
      filteredData.filter((row) => row.visit_type === 'new-visit').length,
    reVisitCount: (filteredData) =>
      filteredData.filter((row) => row.visit_type === 're-visit').length,
    totalMalePatients: (filteredData) =>
      filteredData.filter((row) => row.patient && row.patient.gender === 'Male').length,
    totalFemalePatients: (filteredData) =>
      filteredData.filter((row) => row.patient && row.patient.gender === 'Female').length,
    totalOrdinaryPatients: (filteredData) =>
      filteredData.filter((row) => row.payment && row.payment.type === 'Ordinary').length,
    totalFreePatients: (filteredData) =>
      filteredData.filter((row) => row.payment && row.payment.type === 'Free').length,
    totalCreditPatients: (filteredData) =>
      filteredData.filter((row) => row.payment && row.payment.type === 'Credit').length,
    totalBillAmount: (filteredData) =>
      filteredData.reduce((sum, row) => sum + Number(row.amount || 0), 0),
    totalAmountCollected: (filteredData) =>
      filteredData
        .filter((row) => row.payment && row.payment.type === 'Ordinary')
        .reduce((sum, row) => sum + Number(row.amount || 0), 0),
    totalFreeAmount: (filteredData) =>
      filteredData
        .filter((row) => row.payment && row.payment.type === 'Free')
        .reduce((sum, row) => sum + Number(row.amount || 0), 0),
    totalCreditAmount: (filteredData) =>
      filteredData
        .filter((row) => row.payment && row.payment.type === 'Credit')
        .reduce((sum, row) => sum + Number(row.amount || 0), 0),
  };

  return (
    <Document>
      {Object.entries(groupedReportData).map(([departmentUuid, departmentData], deptIndex) => (
        <Page key={departmentUuid} size="A4" orientation="portrait" style={styles.page}>
          {/* You can add a department-specific header if required */}
          <Text style={[styles.subtitle1, styles.mb8]}>
            {flgDepartment && departmentData.length > 0
              ? `Department: ${departmentData[0].department_name || departmentUuid}`
              : reportTitle || 'OP Report'}
          </Text>

          <View style={{ alignItems: 'center' }}>
            <Text style={[styles.h3, styles.alignItemsCenter]}>
              Sree Narayana Institute of Ayurvedic Studies and Research - Hospital
            </Text>
            <Text> Pangode </Text>
          </View>

          <View style={[styles.gridContainer, styles.mb4]}>
            <View style={styles.col8}>
              <Text style={[styles.subtitle2, styles.mb4]}>{reportTitle}</Text>
            </View>
            <View style={styles.col4}>
              <Text style={[styles.subtitle2, styles.alignRight]}>
                Report Date: {fDate(new Date())}
              </Text>
            </View>
          </View>

          {/* <Text style={[styles.subtitle1, styles.mb8]}>OP Report</Text> */}

          <View style={[styles.table, styles.mb8]}>
            <View style={styles.mb4} fixed>
              <View style={[styles.tableHead]}>
                <View style={styles.tableCell_5}>
                  <Text style={styles.subtitle2}>#</Text>
                </View>

                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>Bill No.</Text>
                </View>

                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>Visit</Text>
                </View>

                <View style={styles.tableCell_15}>
                  <Text style={styles.subtitle2}>MR No.</Text>
                </View>

                <View style={styles.tableCell_25}>
                  <Text style={styles.subtitle2}>Patient Name</Text>
                </View>

                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>Age</Text>
                </View>

                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>Gender</Text>
                </View>

                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>Payment</Text>
                </View>

                <View style={styles.tableCell_10}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>Amount</Text>
                </View>
              </View>
            </View>

            <View>
              {departmentData.map((item, index) => (
                <View wrap={false} style={styles.tableRow} key={item.id}>
                  <View style={styles.tableCell_5}>
                    <Text>{index + 1}</Text>
                  </View>

                  <View style={styles.tableCell_10}>
                    <Text style={styles.subtitle2}>{item.bill_no}</Text>
                  </View>

                  <View style={styles.tableCell_10}>
                    <Text>{item.visit_type}</Text>
                  </View>

                  <View style={styles.tableCell_15}>
                    <Text>{item.patient.medical_record_no}</Text>
                  </View>

                  <View style={styles.tableCell_25}>
                    <Text>{item.patient.patient_name}</Text>
                  </View>

                  <View style={styles.tableCell_10}>
                    <Text>{Age(item.patient.date_of_birth)}</Text>
                  </View>

                  <View style={styles.tableCell_10}>
                    <Text>{item.patient.gender}</Text>
                  </View>

                  <View style={styles.tableCell_10}>
                    <Text>{item.payment_setting}</Text>
                  </View>

                  <View style={styles.tableCell_10}>
                    <Text style={[styles.subtitle2, styles.alignRight]}>
                      {fCurrency(item.amount)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View style={[styles.gridContainer, styles.summary]}>
            <View>
              <View wrap={false} style={styles.tableRow}>
                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL PATIENTS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalPatients(departmentData)}
                  </Text>
                </View>

                <View style={styles.tableCell_5} />

                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL ORDINARY PATIENTS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalOrdinaryPatients(departmentData)}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={styles.tableRow}>
                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL NEW VISITS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.newVisitCount(departmentData)}
                  </Text>
                </View>

                <View style={styles.tableCell_5} />

                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL CREDIT PATIENTS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalCreditPatients(departmentData)}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={styles.tableRow}>
                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL REVISITS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.reVisitCount(departmentData)}
                  </Text>
                </View>

                <View style={styles.tableCell_5} />

                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL FREE PATIENTS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalFreePatients(departmentData)}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={styles.tableRow}>
                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL MALE PATIENTS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalMalePatients(departmentData)}
                  </Text>
                </View>

                <View style={styles.tableCell_5} />

                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>AMOUNT COLLECTED:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalAmountCollected(departmentData)}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={styles.tableRow}>
                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL FEMALE PATIENTS:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalFemalePatients(departmentData)}
                  </Text>
                </View>

                <View style={styles.tableCell_5} />

                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL FREE ALLOWED:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalFreeAmount(departmentData)}
                  </Text>
                </View>
              </View>
              <View wrap={false} style={styles.tableRow}>
                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>TOTAL BILL AMOUNT:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalBillAmount(departmentData)}
                  </Text>
                </View>

                <View style={styles.tableCell_5} />

                <View style={styles.tableCell_40}>
                  <Text style={[styles.mb4]}>BALANCE RECEIVABLE:</Text>
                </View>
                <View style={styles.tableCell_7}>
                  <Text style={[styles.subtitle2, styles.alignRight]}>
                    {calcTotals.totalCreditAmount(departmentData)}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.gridContainer, styles.footer]} fixed>
            <View style={styles.col8}>
              {/* <Text style={styles.subtitle2}>NOTES</Text> */}
              <Text>This is a system-generated report. No signature required.</Text>
            </View>
            <View style={[styles.col4, styles.alignRight]}>
              {deptIndex < Object.keys(groupedReportData).length - 1 && (
                <Text
                  render={({ pageNumber, totalPages }) =>
                    pageNumber !== totalPages ? <Text style={{ pageBreak: 'always' }} /> : null
                  }
                />
              )}
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
}

OpTransactionReportPDF.propTypes = {
  reportData: PropTypes.array,
  reportTitle: PropTypes.string,
  flgDepartment: PropTypes.bool,
};
