import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import OpStatisticsReportPage from 'src/pages/report/reception/op_statistics_report';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// DOCTORS
const DoctorCreatePage = lazy(() => import('src/pages/doctor/new-doctor'));
const DoctorUpdatePage = lazy(() => import('src/pages/doctor/edit-doctor'));
const DoctorsEnquiry = lazy(() => import('src/pages/doctor/doctors-enquiry'));
const DoctorsListPage = lazy(() => import('src/pages/doctor/doctors-list'));

// PATIENTS
const PatientCreatePage = lazy(() => import('src/pages/patient/new-patient'));
const PatientUpdatePage = lazy(() => import('src/pages/patient/edit-patient'));
const PatientsListPage = lazy(() => import('src/pages/patient/patients-list'));
const PatientVisitPage = lazy(() => import('src/pages/patient/patient-visit'));
const PatientRevisitPage = lazy(() => import('src/pages/patient/patient-revisit'));

// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// MASTERS
const DepartmentsListPage = lazy(() => import('src/pages/master/departments-list'));
const PaymentSettingsListPage = lazy(() => import('src/pages/master/payment-settings-list'));

// REPORTS
const OpRegistrationReportPage = lazy(() =>
  import('src/pages/report/reception/op-registration-report')
);
const OpTransactionReportPage = lazy(() =>
  import('src/pages/report/reception/op-transaction-report')
);

// PRINT
const OpBillPage = lazy(() => import('src/pages/print/op-bill'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      // PATIENTS
      {
        path: 'patients',
        children: [
          // { element: <UserProfilePage />, index: true },
          {
            path: 'new_patient',
            element: (
              <RoleBasedGuard hasContent roles={['Reception', 'Doctor']}>
                <PatientCreatePage />
              </RoleBasedGuard>
            ),
          },
          // { path: 'new_patient', element: <PatientCreatePage /> },
          {
            path: ':uuid/edit_patient',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <PatientUpdatePage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':uuid/patient_visit',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <PatientVisitPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'revisit',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <PatientRevisitPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'list',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <PatientsListPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      // USER
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <PatientCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      // DOCTORS
      {
        path: 'doctors',
        children: [
          {
            path: 'new',
            element: (
              <RoleBasedGuard hasContent roles={['Admin']}>
                <DoctorCreatePage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':uuid/edit_doctor',
            element: (
              <RoleBasedGuard hasContent roles={['Admin']}>
                <DoctorUpdatePage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'list',
            element: (
              <RoleBasedGuard hasContent roles={['Admin']}>
                <DoctorsListPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'enquiry',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <DoctorsEnquiry />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      // MASTERS
      {
        path: 'masters',
        children: [
          {
            path: 'departments',
            element: (
              <RoleBasedGuard hasContent roles={['Admin']}>
                <DepartmentsListPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'payment_settings',
            element: (
              <RoleBasedGuard hasContent roles={['Admin']}>
                <PaymentSettingsListPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      // REPORTS
      {
        path: 'report',
        children: [
          {
            path: 'op_registration_report',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <OpRegistrationReportPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'op_transaction_report',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <OpTransactionReportPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'op_statistics_report',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <OpStatisticsReportPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      // PRINT
      {
        path: 'print',
        children: [
          {
            path: ':uuid/op_bill',
            element: (
              <RoleBasedGuard hasContent roles={['Reception']}>
                <OpBillPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },
    ],
  },
];
