import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
// api
import { reportOpStatistics } from 'src/api/report';
// utils
import { fData } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hooks';
// assets
import { countries } from 'src/assets/data';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFSelect,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { fDate } from 'src/utils/format-time';
import ReportFilter from './common/report-filter';
import OpStatisticsReportDetails from './op-statistics-report-details';

// ----------------------------------------------------------------------

export default function OpStatisticsReportForm({ currentUser }) {
  const flgReport = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [reportTitle, setReportTitle] = useState('OP Transaction Report');
  const [reportData, setReportData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const NewReportSchema = Yup.object().shape({
    date_from: Yup.mixed().nullable().required('From date is required'),
    date_to: Yup.mixed()
      .required('To date is required')
      .test(
        'date-min',
        'To Date must be later than From Date',
        (value, { parent }) => value.getTime() >= parent.date_from.getTime()
      ),
  });

  const defaultValues = useMemo(
    () => ({
      date_from: new Date(),
      date_to: new Date(),
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewReportSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (data) => {
    flgReport.onTrue();
    try {
      setReportTitle(
        `OP Statistics Report from ${fDate(data.date_from, 'dd-MM-yyy')} to ${fDate(
          data.date_to,
          'dd-MM-yyy'
        )}`
      );
      const response = await reportOpStatistics(data);
      let error_message = 'Something went wrong';
      if (response && response.success === true) {
        const { departments, report } = response.data;
        // console.log(report);
        setReportData(report);
        setDepartmentData(departments);
        reset();
        enqueueSnackbar('Report generated successfully');
        flgReport.onTrue();
        // router.push(paths.print.op_bill(visit));
      } else {
        flgReport.onFalse();
        if (response.reason) {
          error_message = response.reason;
          error_message += JSON.stringify(response.message);
        }
        enqueueSnackbar(`Sorry! ${error_message}. Please try again`, { variant: 'error' });
      }
    } catch (error) {
      flgReport.onFalse();
      console.error(error);
    }
  });

  if (flgReport.value) {
    if (reportData.length) {
      return (
        <OpStatisticsReportDetails
          reportTitle={reportTitle}
          reportData={reportData}
          departmentData={departmentData}
          flgReport={flgReport}
        />
      );
    }
    return <LoadingScreen />;
  }
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card>
        {/* <ReportFilter isLoading={isSubmitting} /> */}
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ p: 3, bgcolor: 'background.neutral' }}
        >
          <Controller
            name="date_from"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Date from"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
          />

          <Controller
            name="date_to"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Date to"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
          />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Report
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

OpStatisticsReportForm.propTypes = {
  currentUser: PropTypes.object,
};
